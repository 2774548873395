 
/* login */
.login {
  position: absolute;

  width: 100%;
  height: auto;
  min-height: 100%;
  overflow: hidden;

  top: 0px;
  
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;

  color: var(--color-text);
  background-color: var(--color-background);

  z-index: -2;
}

.login-spacer {
  height: 69px;
}

.login-content {
  width: 100%;
  height: auto;
  min-height: 100%;

  top: 0px;

  gap: 10%;
  
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
}

/* text/heading */
.login-heading-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  
  padding: 72px 58px;

  gap: 30px;

  box-sizing: border-box;

  width: 533px;
  height: 533px;

  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #444444;
  backdrop-filter: blur(7px);

  border-radius: 10px;
}

.login-heading {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 89px;

  color: #FFFFFF;
}

.login-heading-stylized {
  background: linear-gradient(105.91deg, #FF4280 35.41%, #8D1DFF 73.31%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);


  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-subheading {
  width: 100%;

  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  color: #FFFFFF;
  
  text-align: left;
}

/* card */
.login-card {
  display: flex;
  flex-direction: column;

  width: 450px;

  gap: 20px;

  align-items: start;
}

.login-card-heading-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 20px;

  gap: 10px;

  font-family: 'Avenir Next';
  font-style: normal;

  color: #FFFFFF;
}

.login-card-heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
}

.login-card-subheading {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}

/* checkbox and forgot password */
.login-card-row {
  display: flex;
  flex-direction: row;

  width: 100%;

  align-items: center;
  justify-content: center;

  height: 30px;
}

.login-card-spacer {
  flex: 1 1 auto;
}

.login-card-row-checkbox-text {
  justify-content: start;

  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  color: #D7D7D7;
}

/* Create a custom checkbox */
.login-card-checkbox {
  margin: auto;
  
  justify-items: start;

  --login-card-checkbox-color: white;
  --login-card-checkbox-disabled: white;

  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;

  display: grid;
  grid-template-columns: 1em auto;
  gap: 0px;
  }

.login-card-checkbox + .login-card-checkbox {
  margin-top: 1em;
}

.login-card-checkbox--disabled {
  color: var(--login-card-checkbox-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  color: white;
  width: 16px;
  height: 16px;
  border: 2px solid currentColor;
  border-radius: 5px;

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: center center;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--login-card-checkbox-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(0.7);
}

input[type="checkbox"]:hover {
  outline: max(2px, 2px) solid currentColor;
  outline-offset: max(2px, 2px);
}

input[type="checkbox"]:disabled {
  --login-card-checkbox-color: var(--login-card-checkbox-disabled);

  color: var(--login-card-checkbox-disabled);
  cursor: not-allowed;
}

.login-card-row-text-stylized {
  padding: 3px 0px;

  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  background: linear-gradient(90deg, #FF4280 0%, #8D1DFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-fill-color: transparent;
}

/* --- or --- styling */
.login-card-white-bar {
  width: 40%;

  border: 1px solid #FFFFFF;
}

.login-card-row-text {
  flex: 1 1 auto;

  width: 10%;

  text-align: center;

  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  
  color: #FFFFFF;
}

/* mobile */
@media (max-width:1000px)  { 
  .login {
    position: absolute;
  
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
  
    top: 0px;
    
    display: flex;
    flex-direction: column;
    
    align-items: center;
    justify-content: center;
  
    color: var(--color-text);
    background-color: var(--color-background);
  
    z-index: -2;
  }

  .login-content {
    width: 100%;
    height: 100%;

    padding: 0px;
    gap: unset;
  
    display: flex;
    flex-direction: column;
  
    align-items: center;
    justify-content: space-between;
  }

  /* text/heading */
  .login-heading-container {
    padding: 0px 10px;

    width: 100%;
    height: auto;
  }

  .login-heading {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;

    padding: 10px 10px 10px 10px;
  
    color: #FFFFFF;
  }
  
  .login-subheading {
    display: none;
  }

  .login-card {
    display: flex;
    flex-direction: column;
  
    width: 100%;
    padding: 10px 20px 50px 20px;
    margin: 0;
  
    gap: 20px;
  
    align-items: start;
  }
}