.landing {
  width: 100%;
  height: auto;

  padding: 32px 27px 0px 27px;
  
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: start;

  background-color: var(--color-landing-background);

  overflow-x: hidden;
}

.landing-container {
  width: 1370px;
  max-width: 100%;
}

.landing-card {
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  background: rgba(32, 32, 32, 1);
  border: 1px solid #444444;
  border-radius: 30px;
}

/* hero section */
.landing-hero {
  display: flex;
  flex-direction: column;
}

/* hero navbar */
.landing-hero-navbar {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 45.5px 73px 0px 73px;

  z-index: 1;
}

.landing-hero-navbar-hidden {
  visibility: hidden;
}

.landing-hero-navbar-scroll {
  position: fixed;
  top: 0px;
  left: 35px;
  right: 35px;
  
  max-width: 100%;

  padding: 20px 60px;

  margin: auto;

  background: rgba(34, 34, 34, 0.95);
  border: 1px solid #444444;
  border-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  z-index: 10;
}

.landing-hero-navbar-scroll-hidden {
  display: none;
}

/* hero nav title */
.landing-nav-title {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 22px;
  line-height: 140%;

  z-index: 1;
}

.landing-nav-title-dot {
  background: linear-gradient(107.96deg, var(--color-primary) 70.99%, var(--color-secondary) 75.21%);
  
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.landing-nav-title > a {
  color: white;
  text-decoration: none;
}

/* hero nav links */
.landing-nav-links {
  display: flex;
  flex-direction: row;

  gap: 43px;

  align-items: center;
  justify-content: center;

  z-index: 1;
}

.landing-nav-link {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  color: var(--color-text);
}

/* hero nav button */
.landing-nav-button {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 8px 25px;

  background: linear-gradient(270deg, #FF4280 -6.02%, #8D1DFF 100%);
  border-radius: 5px;

  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  &:hover {
    background: linear-gradient(180deg, #FF4280 -6.02%, #8D1DFF 100%);
  }
}

/* landing hero content */
.landing-hero-content {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: start;

  padding: 0px 73px 0px 73px;
  gap: 100px;
}

.landing-hero-content-left {
  max-width: 400px;

  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;

  gap: 20px;

  z-index: 1;
}

.landing-hero-content-left-heading {
  font-weight: 700;
  font-size: 42px;
  line-height: 140%;
}

.landing-hero-content-left-subheading {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.landing-hero-content-left-pencil {
  margin-top: -17px;
  margin-left: 60px;
}

.landing-hero-content-left-button {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 20px;
  padding: 10px 29px;

  gap: 10px;

  width: 168px;
  height: 42px;

  background: var(--color-primary);
  border-radius: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.landing-hero-content-right {
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;
  justify-items: center;

  flex: 1;

  width: 500px;
  height: 500px;

  margin: 100px auto 100px auto;
}

.landing-hero-content-right-container {
  position: relative;
  display: block;

  width: 1px;
  height: 1px;

  left: -100px;
  top: 0px;

  align-self: center;
  justify-self: center;
}

.landing-hero-content-right-image-tinder {
  position: absolute;

  top: -240px;
  left: -170px;
  height: 500px;

  transform: rotate(-8deg);

  z-index: 3;
}

.landing-hero-content-right-image-hinge {
  position: absolute;

  top: -200px;
  left: -30px;
  height: 540px;

  filter: drop-shadow(8px 14px 14px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  transform: rotate(-6deg);

  z-index: 2;
}

.landing-hero-content-right-image-heart {
  position: absolute;

  top: 140px;
  left: -200px;

  height: 200px;

  filter: drop-shadow(19px 25px 30px rgba(0, 0, 0, 0.13));
  transform: rotate(-11deg);

  z-index: 4;
}

.landing-hero-content-right-image-heart-contained {
  position: absolute;

  top: -45px;
  left: 240px;

  height: 150px;

  filter: blur(1px);
  transform: rotate(0deg);

  z-index: 4;
}

/* how it works */
.landing-hiw-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;
}

.landing-hiw-content {
  display: flex;
  flex-direction: row
}

.landing-hiw-content-left {
  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;

  padding: 70px;

  gap: 47px;
}

.landing-hiw-content-left-heading {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
}

.landing-hiw-content-left-items {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  column-gap: 50px;
  row-gap: 50px;
}
.landing-hiw-content-left-item {
  display: flex;
  flex-direction: column;

  align-items: start;
  
  gap: 16px;
}

.landing-hiw-content-left-item-image {
  height: 67px;
}

.landing-hiw-content-left-item-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.landing-hiw-content-left-item-subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.landing-hiw-content-right {
  position: relative;

  width: 50%;
  max-width: 450px;

  margin: 50px auto 100px auto;
  padding: 10px 10px 0px 0px;
}

.landing-how-content-right-image-1 {
  width: 100%;
}

.landing-how-content-right-image-2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

/* Features */
.landing-features-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}

.landing-features {
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;
  justify-content: center;

  gap: 67px;
}

.landing-features-heading {
  display: table;
  text-align: center;

  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 32px;
  line-height: 140%; 
}

.landing-features-content {
  display: flex;
  flex-direction: column;

  width: 100%;

  gap: 24px;
}

.landing-features-content-row {
  display: flex;
  flex-direction: row;

  gap: 23px;
}

.landing-features-content-row-item {
  position: relative;
  display: flex;

  height: 300px;

  justify-content: end;

  overflow: hidden;
}

.landing-features-content-row-item-small {
  flex: 1;
  flex-grow: 1;
}

.landing-features-content-row-item-large {
  flex: 1;
  flex-grow:1.2;

  padding: 36px;
  gap: 20px;

  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: center;
}

.landing-features-content-row-item-2 {
  padding: 31px;
}

.landing-features-content-row-item-3 {
  flex-direction: row;

  align-items: center;
  justify-content: center;

  padding: 0px 47px 0px 47px;

  gap: 33px;
}

.landing-features-content-row-item-6 {
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 33px;
}

.landing-features-content-row-item-number {
  box-sizing: border-box;

  margin: 0px auto 25px 0px;
  padding: 15px;

  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  border: 1px solid #444444;
  border-radius: 50%;
}

.landing-features-content-row-item-heading {
  font-weight: 600;
  font-size: 28px;
  line-height: 100%;
}

.landing-features-content-row-item-subheading {
  width: 80%;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

/* pricing */
.landing-pricing-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  padding-top: 100px;

  align-items: center;
  justify-content: center;
}

/* community */
.landing-community-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  padding-top: 100px;

  align-items: center;
  justify-content: center;

  z-index: 1;
}

.landing-community-container {
  display: flex;
  flex-direction: column;

  gap: 25px;

  align-items: center;
  justify-content: center;

  text-align: center;

  z-index: 1;
}

.landing-community-pre-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 21px;
  gap: 10px;
  
  width: 120px;
  height: 42px;
  
  background: linear-gradient(91.1deg, rgba(255, 66, 128, 0.4) 5.4%, rgba(141, 29, 255, 0.4) 95.02%);
  border: 1px dashed var(--color-primary);
  border-radius: 10px;

  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
}

.landing-community-header {
  font-weight: 600;
  font-size: 42px;
  line-height: 140%;
}

.landing-community-subheader {
  max-width: 880px;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.landing-community-post-header {
  margin-top: 40px;

  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.landing-community-pictures {
  position: relative;

  width: 100%;
  display: grid;

  padding: 0 122px 0 122px;
  gap: 20px;

  grid-template-columns: 2fr 2fr 2fr;
  grid-template-rows: repeat(2, 1fr);
}

.landing-community-picture {
  display: flex;

  width: 100%;
  height: 100%;

  min-width: 0;
  min-height: 0;
}

.landing-community-picture {
  width: 100%;
  height: auto;
}

.landing-community-picture > img {
  width: 100%;
  height: auto;

  border-radius: 20px;
}

.landing-community-picture-account {
  position: absolute;

  bottom: 0px;
  left: 0px;

  padding: 0px 0px 19px 24px;

  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  text-align: center;
}


/* testimonials */
.landing-testimonials-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  margin: auto;
  padding-top: 100px;

  align-items: center;
  justify-content: center;

  z-index: 0;
}

.landing-testimonials-container {
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  column-gap: 70px;
  row-gap: 0px;

  align-items: center;
  justify-content: center;

  text-align: start;
}

.landing-testimonials-card-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 29px 28px;
  gap: 16px;

  width: 504.21px;
  height: 204.77px;

  background: #222222;
  border: 1px solid #444444;
  box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  transform: rotate(-5.34deg);
}

.landing-testimonials-card-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  justify-self: center;
  padding: 29px 28px;
  gap: 16px;

  width: 504.21px;
  height: 204.77px;

  background: #222222;
  border: 1px solid #444444;
  box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  transform: rotate(3.07deg);
}

.landing-testimonials-card-3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;

  margin-top: -70px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  justify-self: center;

  padding: 29px 28px;
  gap: 16px;

  width: 674.97px;
  height: 160.77px;

  background: #222222;
  border: 1px solid #444444;
  box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  transform: rotate(1.23deg);
}

.landing-testimonials-stars {
  align-self: start;
}

/* trial */
.landing-trial-card {
  background: transparent;
  border: 0px;
  border-radius: 30px;

  display: flex;
  flex-direction: column;

  margin: auto;

  align-items: center;
  justify-content: center;
}

.landing-trial-container {
  position: relative;

  background: #222222;
  border: 1px solid #444444;
  box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
  border-radius: 27px;

  display: flex;
  flex-direction: column;

  padding: 50px 180px 50px 180px;

  gap: 30px;

  align-items: center;
  justify-content: center;

  text-align: center;
}

.landing-trial-header {
  font-weight: 400;
  font-size: 22px;
  line-height: 22px;
}

.landing-trial-subheader {
  max-width: 800px;

  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
}

.landing-trial-button {
  width: auto;
  padding: 12px 19px;
}

/* footer */
.landing-footer-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  margin-top: 100px;

  display: grid;

  overflow: hidden;
}

.landing-footer-container {
  background: #222222;

  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  gap: 5px;
  padding-bottom: 30px;

  overflow: hidden;
}

.landing-footer-bottom {
  z-index: 1;
}

.landing-footer-bottom > a {
  text-decoration: underline;

  color: var(--color-text);

  padding: 10px;

  &:hover {
    color: var(--color-primary-hover);
  }
}

/* mobile */
@media (max-width:1000px)  { 
  .landing-card {
    margin-bottom: 400px;
  }

  .mobile-landing-hero-navbar {
    position: fixed;

    width: auto;

    background-color: rgba(32, 32, 32, 1);
    border: 1px solid #444444;
    border-radius: 15px;

    display: flex;
    flex-direction: row;

    justify-self: center;
  
    align-items: space-between;
    justify-content: space-between;
  
    padding: 10px 20px 10px 20px;
    margin: auto 26px auto 26px;

    top: 15px;
    left: 0px;
    right: 0px;
  
    z-index: 9;
  }

  .landing-hero-navbar-exit {
    align-self: end;
  }

  .mobile-landing-hero-navbar-popup-hidden {
    display: none !important;
  }

  .mobile-landing-hero-navbar-popup-visible {
    display: flex !important;
  }
  
  .mobile-landing-hero-navbar-popup {
    position: fixed;
    width: 66%;
    height: 100%;

    top: 0px;
    right: 0px;

    background: #222222;

    display: flex;
    flex-direction: column;
  
    align-items: start;
    justify-content: end;
  
    padding: 20px 20px 20px 34px;
    gap: 40px;
  
    z-index: 10;
  }

  .landing-hero {
    margin-top: 70px;
    padding-top: 30px;
  }

  .landing-nav-title {
    font-size: 30px;
  }
  
  .landing-nav-links {
    flex-direction: column;
    gap: 24px;
  }

  .landing-nav-link {
    margin-left: 0px;
  }

  .landing-hero-navbar-spacer {
    width: 15px;
    flex: 1;
    flex-grow: 2;
  }

  .landing-hero-content-icons {
    display: flex;
    flex-direction: row;

    align-items: start;

    min-width: 0;
    min-height: 0;
    margin: auto 10px auto 0px;

    justify-self: end;
  }

  /* landing hero content */
  .landing-hero-content {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: start;

    padding: 0px 73px 0px 73px;
    gap: 100px;
  }

  .landing-hero-content-left-heading {
    font-size: 34px;
  }

  .landing-hero-content-right {
    width: 100%;
    margin: 10px;
  }

  .landing-hero-content-right-container {
    top: -50px;
  }
  
  .landing-hero-content-right-image-tinder {
    top: 0px;
    left: -60px;
    height: 380px;
  
    transform: rotate(0);
  }
  
  .landing-hero-content-right-image-hinge {
    top: 40px;
    left: 10px;
    height: 400px;
  
    filter: drop-shadow(8px 14px 14px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    transform: rotate(0);
  }
  
  .landing-hero-content-right-image-heart {
    top: 260px;
    left: -100px;
  
    height: 190px;
  
    filter: drop-shadow(19px 25px 30px rgba(0, 0, 0, 0.13));
    transform: rotate(0);
  }
  
  .landing-hero-content-right-image-heart-contained {
    top: 190px;
    left: 150px;
  
    height: 150px;
  
    filter: blur(1px);
    transform: rotate(0);
  }

  /* how it works */
  .landing-hiw-card {
    margin-bottom: 130px;
  }

  .landing-hiw-content {
    display: flex;
    flex-direction: column;
  }

  .landing-hiw-content-left {
    align-items: center;

    padding: 0px;
  }

  .landing-hiw-content-left-items {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  
    gap: 50px;
  }

  .landing-hiw-content-left-item {
    align-items: center;
    justify-content: center;

    text-align: center;
  }

  .landing-hiw-content-right {
    display: none;
  }

  /* features */
  .landing-features-card {
    margin: 0;
  }

  .landing-features-heading {
    font-size: 20px;
  }

  .landing-features-content {
    gap: 60px;
  }

  .landing-features-content-row {
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  
    row-gap: 0px;
  }

  .landing-features-content-row-item-small {
    border: none;
    background-color: transparent;

    grid-column-start: 1;
    grid-row-start: 1;

    margin: auto auto 0px auto;
    padding: 0px;

    height: 200px;
    width: calc(100% - 15px * 2);
  }
  
  .landing-features-content-row-item-large {
    overflow: visible;

    grid-column-start: 1;
    grid-row-start: 1;

    margin: 0 0 0 0;
    padding-left: 15px;
    padding-right: 15px;

    justify-content: start;

    height: 430px;
  }

  .landing-features-content-row-item-heading {
    font-size: 28px;
  }

  .landing-features-content-row-item-subheading {
    width: 100%;

    font-size: 15px;
  }

  .landing-features-content-row-item-2 {
    background-image: url('../../assets/icons_landing/mobile-card-1.png');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .landing-features-content-row-item-3 {
    background-image: url('../../assets/icons_landing/mobile-card-2.png');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .landing-features-content-row-item-6 {
    background-image: url('../../assets/icons_landing/mobile-card-3.png');

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .landing-features-content-row-item-number {
    margin-top: -60px;

    background-color: #222222;
  }

  /* pricing */
  .landing-pricing-card {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  /* community */
  .landing-community-card {
    margin-bottom: 0px;
  }

  .landing-community-pre-header {
    font-size: 15px;
  }

  .landing-community-pictures {
    grid-template-columns: 2fr 2fr;
    padding: 0;
  }

  .landing-community-picture-account {
    padding: 0px 0px 5px 10px;
  }

  /* testimonials */
  .landing-testimonials-card {
    margin-bottom: 0px;
  }

  .landing-testimonials-container-pos-1 {
    left: 0% !important;
  }

  .landing-testimonials-container-pos-2 {
    left: -100% !important;
  }

  .landing-testimonials-container-pos-3 {
    left: -200% !important;
  }

  .landing-testimonials-container {
    transition: all 250ms ease-in;

    width: 100%;
    height: auto;

    margin: 0px 0px 0px 0px;

    left: 0px;

    display: flex;
    flex-direction: row;
  
    gap: 10px;
  
    align-items: stretch;
    justify-content: start;
  
    text-align: start;
  }

  .landing-testimonials-card-1 {
    height: auto;

    flex: 1 0 100%;
    flex-grow: 1;

    padding: 30px;

    margin: 0px;

    transform: rotate(0);
  }

  .landing-testimonials-card-2 {
    height: auto;

    flex: 1 0 100%;
    flex-grow: 1;

    padding: 30px;

    margin: 0px;

    transform: rotate(0);
  }

  .landing-testimonials-card-3 {
    height: auto;

    flex: 1 0 100%;
    flex-grow: 1;

    padding: 30px;

    margin: 0px;

    transform: rotate(0);
  }

  .landing-testimonials-button-container {
    z-index: 2;
  }
  
  .landing-testimonials-button {
    margin: 50px 5px 50px 5px;
  
    border: 0px;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
  }

  /* trial */
  .landing-trial-card {
    margin: 0px;
  }

  .landing-trial-container {
    width: 100%;
    padding: 40px 20px;
    margin: 0px;
  }

  .landing-trial-header {
    font-size: 18px;
  }

  .landing-trial-subheader {
    font-size: 22px;
  }

  /* footer */
  .landing-footer-card {
    border-bottom-width: 0;

    margin-top: 30px;
    margin-bottom: 0px;
  }

  .landing-footer-container {
    background: #222222;

    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr auto;

    gap: 5px;
    padding-bottom: 30px;

    overflow: hidden;
  }

  .landing-hero-navbar-footer {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    padding: 20px;

    gap: 20px;
  }

  .landing-hero-navbar-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .landing-footer-bottom-links {
    display: flex;
    flex-direction: row;

    width: 100%;
    margin-top: 0px;
  }

  .landing-footer-bottom {
    margin: 30px auto auto auto;
  }

  .landing-footer-bottom-links > a:nth-child(1) {
    margin-left: 0px;
  }

  .landing-footer-bottom-links > a:nth-child(2) {
    margin-right: 0px;
  }
}