
/* generate */
.generate {
    position: absolute;
    width: 100%;
    height: auto;

    top: 69px;
    bottom: 0px;
    max-height: 100%;
  
    background: var(--color-background);

    display: flex;
    flex-direction: row;
}

.generate-flex {
    bottom: auto;
    max-height: none;
}

/* options bar */
.generate-options {
    box-sizing: border-box;

    width: 333px;

    background: #1A1A1A;
    border: 1px solid #232526;

    padding: 20px 39px;

    display: flex;
    flex-direction: column;
    align-items: start;
}

/* options bar sliding animation */
/* The animation code */
@keyframes generate-options-closed-animation {
    from {
        width: 333px;
        padding-left: 39px;
        padding-right: 39px;
    }
    to {
        width: 40px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@keyframes generate-options-open-animation {
    from {
        width: 40px;
        padding-left: 5px;
        padding-right: 5px;
    }
    to {
        width: 333px;
        padding-left: 39px;
        padding-right: 39px;
    }
}

.generate-options-closed {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;

    animation: generate-options-closed-animation 0.5s ease-in-out;
}

.generate-options-open {
    width: 333px;
    padding-left: 39px;
    padding-right: 39px;

    animation: generate-options-open-animation 0.5s ease-in-out;
}

/* options top */
.generate-options-top {
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;

    text-decoration: none; 
    text-decoration-line: underline;

    color: #C1C1C1;

    display: flex;
    flex-direction: row;
    align-items: end;
}

.generate-options-top > a {
    text-decoration: none; 
    color: white;

    display: flex;
    align-items: center;
}

.generate-options-top-left {
    margin-left: 0px;
}

.generate-options-top-left > img {
    margin-left: 0px;
}

.generate-options-top-right {
    margin-right: 0px;

    &:hover {
        cursor: pointer;
    }
}

.generate-options-top-text {
    margin-left: 7px;
}

/* options rest */
.generate-options-header {
    margin: 30px auto 30px 0px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    color: #FFFFFF;
}

.generate-options-elements {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: start;
}

.generate-options-element-title {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #FFFFFF;
}

.generate-options-element-select {
    width: 253px;
    height: 42px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.generate-options-element-select > select {
    width: 100%;
    height: 100%;

    padding: 9px 16px;

    background: #3A3A3A;
    border-radius: 5px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #FFFFFF;
}

/* options bottom */
.generate-options-bottom {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.generate-options-bottom-title {
    margin-top: 25px;
    margin-bottom: 13px;

    display: flex;
    flex-direction: row;
    justify-items: start;

    font-family: 'Avenir Next';
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
}

.generate-options-bottom-title-1 {
    font-weight: 500;
    color: #FFFFFF;
    margin-right: 7px;
}

.generate-options-bottom-title-2 {
    font-weight: 700;
    color: #FF4280;
}

.generate-options-bottom-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 10px;
    isolation: isolate;

    width: 90%;
    height: 56px;

    background: #232526;
    border-radius: 5px;
}

.generate-options-bottom-card-image {
    height: 90%;
}

.generate-options-bottom-card-text {
    color: #FFFFFF;
}

.generate-options-bottom-card-text-title {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
}

.generate-options-bottom-card-text-subtitle {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
}

.generate-options-bottom-card-buy {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 5px;
    gap: 10px;

    width: 55px;
    height: 19px;
    margin-left: 10px;

    border: 0.8px solid #FF4280;
    border-radius: 5px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    
    color: #FF4280;
    background-color: transparent;
}

.generate-options-bottom-button {
    margin: 25px auto 0px auto;
}

/* gallery empty */
.generate-gallery {
    position: relative;

    width: 100%;
    height: 100%;

    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.generate-gallery-item-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
  }
  
  .generate-gallery-item-heading {
    margin-bottom: 1rem;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
  }
  
  .generate-gallery-item-paragraph {
    width: 80%;
    
    margin-bottom: 2rem;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    text-align: center;

    color: #FFFFFF;
  }
  
  .generate-gallery-item-button {
    outline: none;
    border: none;

    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 28px;
    gap: 10px;
    
    width: auto;
    height: 42px;
    
    background: #8D1DFF;
    border-radius: 5px;
    
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #FFFFFF;

    &:hover {
        background-color: #8D1DFF;
      }
  }

/* view container */
.generate-view {
    width: 100%;
    height: 100%;

    overflow: hidden; /* test this */

    margin: auto;

    display: flex;
    flex-direction: column;

    flex: 1;
    min-width: 0px;
}

/* gallery bar */
.generate-gallery-bar {
    width: 100%;
    height: 100%;

    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: start;

    background: #1A1A1A;

    flex: 0;
}

.generate-gallery-bar-main {
    width: 100%;
    height: 47px;

    display: flex;
    flex-direction: row;
    justify-content: end;

    gap: 40px;
    padding: 10px 40px;
}

.generate-gallery-bar-main-view-filter {
    flex: 0 0 81px;
    width: 81px;

    background-image: url('../../assets/icons/filter-view-single-dropdown.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;

    &:hover {
        cursor: pointer;
        background-color: rgba(107, 107, 107, 0.1);
        background-blend-mode: multiply;
    }
}

.generate-gallery-bar-main-view-filter-list {
    box-sizing: border-box;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 216px;
    height: auto;
    
    position: relative;
    left: 0px;
    top: 47px;

    background: #383838;
    border: 0.5px solid #232526;
    border-radius: 5px;

    z-index: 20;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.generate-gallery-bar-main-view-filter-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px 7px 17px;
    gap: 8px;

    width: 100%;

    color: white;
    background: transparent;
    border-radius: 5px;

    &:hover {
        background: #8D1DFF;
    }
}

.generate-gallery-bar-main-sort-filter {
    flex: 0 0 87px;
    width: 87px;

    background-image: url('../../assets/icons/filter-sort-dropdown.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:hover {
        cursor: pointer;
        background-color: rgba(107, 107, 107, 0.1);
        background-blend-mode: multiply;
    }
}

.generate-gallery-bar-main-date-filter {
    flex: 0 0 165px;
    width: 165px;

    background-image: url('../../assets/icons/filter-date-dropdown.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &:hover {
        cursor: pointer;
        background-color: rgba(107, 107, 107, 0.1);
        background-blend-mode: multiply;
    }
}

.generate-gallery-bar-progress {
    overflow: hidden;

    width: 100%;
    height: 4px;

    background: #D9D9D9;
}

.generate-gallery-bar-progress-fill {
    width: 30%;
    height: 4px;

    background: var(--color-primary);
}


/* three view */
.generate-gallery-threeview {
    width: 90%;
    height: 100%;

    min-height: 0;

    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.generate-gallery-threeview-image-1 {
    margin: 10px;
    max-height:70%;
    min-width: 0;
    min-height: 0;

    flex: 0 1 auto;
}

.generate-gallery-threeview-image-2 {
    margin: 10px;
    max-height: 90%;
    min-width: 0;
    min-height: 0;

    flex: 0 1 auto;
}

.generate-gallery-threeview-image-3 {
    margin: 10px;
    max-height: 70%;
    min-width: 0;
    min-height: 0;

    flex: 0 1 auto;
}

.generate-gallery-threeview-left {
    margin-left: -40px;
    z-index: 1;
}

.generate-gallery-threeview-right {
    margin-right: -40px;
    z-index: 1;
}

/* gallery carousel */
.generate-gallery-carousel {
    width: 100%;
    height: 154px;

    min-width: 0px;
    flex: 0 1 auto;

    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    flex: 0;

    background: #3A3A3A;
}

@keyframes generate-gallery-carousel-open-animation {
    0% {
        transform: scale(1,0);
        height: 0;
    }
    100% {
        transform: scale(1);
        height: 154px;
    }
}

@keyframes generate-gallery-carousel-closed-animation {
    0% {
        transform: scale(1);
        height: 154px;
    }
    100% {
        transform: scale(1,0);
        height: 0;
    }
}

.generate-gallery-carousel-open {
    height: 154px;
    transform: scale(1);
    animation: generate-gallery-carousel-open-animation 0.5s ease-in-out;
}

.generate-gallery-carousel-closed {
    height: 0;
    transform: scale(1,0);
    animation: generate-gallery-carousel-closed-animation 0.5s ease-in-out;
}

.generate-gallery-carousel-tab {
    width: 110px;
    height: 33px;

    background-image: url('../../assets/icons/tab.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    display: flex;
    align-items: center;
    justify-content: end;
}

.generate-gallery-carousel-triangle {
    width: 18px;
    height: 14px;
    
    padding-top: 2px;
    margin: auto 25px auto auto;
}

.generate-gallery-carousel-images {
    height: 100%;
    width: 100%;
    min-width: 0;

    flex: 1 1 auto;

    display: flex;
    flex-direction: row;

    gap: 32px;
    padding: 17px 0 17px 26px;

    overflow-x: auto;
    overflow-y: hidden;
}

.generate-gallery-carousel-images::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    height: 9px
}
  
.generate-gallery-carousel-images::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 0px rgba(255, 255, 255, .5);
}

.generate-gallery-carousel-image {
    min-width: 0;
    min-height: 0;

    flex: 0 0 auto;

    width: 121px;
    height: 121px;

    background: rgba(73, 73, 73, 0.8);
    border-radius: 5px;

    object-fit: cover;
}

/* gallery view */
.generate-gallery-galleryview {
    width: 95%;
    height: 100%;
    min-height: 0;

    margin: 1.5em auto auto auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: start;
    align-items: flex-start;
    align-content: flex-start;

    gap: 1em 1.8%;

    overflow-y: auto;
}

.generate-gallery-galleryview-item {
    display: grid;
    width: 23.5%;
}

.generate-gallery-galleryview-image {
    grid-column-start: 1;
    grid-row-start: 1;

    width: 100%;
}

.generate-gallery-galleryview-image {
    border: 4px solid #6C6A69;
}

.generate-gallery-galleryview-image-ellipse {
    grid-column-start: 1;
    grid-row-start: 1;

    width: 35px;
    height: 35px;

    margin: 10px auto auto 10px;

    overflow: visible;

    background-color: rgba(20, 20, 20, 0.8);
    border-radius: 50%;

    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;

    color: #FFFFFF;
}

.generate-gallery-galleryview-image-heart {
    grid-column-start: 1;
    grid-row-start: 1;

    width: 12%;
    height: 12%;

    margin: 6px 12px auto auto;

    overflow: visible;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;


    cursor: pointer;
    z-index: 1;
}

.heart-filled {
    background-image: url('../../assets/icons/heart-filled.svg');
}

.heart-empty {
    background-image: url('../../assets/icons/heart-empty.svg');
}

/* phone view */
.generate-gallery-phoneview {
    height: 100%;

    min-height: 0;

    margin: auto;
    padding: 10px 0;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
}

.generate-gallery-phoneview-phone {
    margin: 10px;
    max-height: 90%;
    min-width: 0;
    min-height: 0;

    flex: 0 1 auto;
}



/* mobile */
@media (max-width:1000px)  { 
    .generate-options {
        box-sizing: border-box;
    
        width: 100%;
        transform: scale(1);
    
        background: #1A1A1A;
        border: 1px solid #232526;
    
        padding: 20px 39px;
    
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    /* options bar sliding animation */
    /* The animation code */
    @keyframes generate-options-closed-animation {
        0% {
            width: 100%;
            padding-left: 39px;
            padding-right: 39px;
            transform: scale(1);
        }
        100% {
            width: 0;
            padding: 0;
            margin-left: -3px;
            transform: scale(0,1);
        }
    }

    @keyframes generate-options-open-animation {
        0% {
            width: 0;
            padding: 0;
            margin-left: -3px;
            transform: scale(0,1);
        }
        100% {
            width: 100%;
            padding-left: 39px;
            padding-right: 39px;
            transform: scale(1);
        }
    }

    .generate-options-closed {
        width: 0px;
        margin-left: -3px;
        padding-left: 0px;
        padding-right: 0px;
        transform: scale(0,1);
    
        animation: generate-options-closed-animation 0.5s ease-in-out;
    }
    
    .generate-options-open {
        width: 100%;
        padding-left: 39px;
        padding-right: 39px;
        transform: scale(1);
    
        animation: generate-options-open-animation 0.5s ease-in-out;
    }
    /* three view */
    .generate-gallery-threeview-image-1 {
        display: none;
        margin: 10px;
        max-height:70%;
        min-width: 0;
        min-height: 0;
    
        flex: 0 1 auto;
    }
    
    .generate-gallery-threeview-image-2 {
        margin: -20px;
        max-height: 90%;
        min-width: 0;
        min-height: 0;
    
        flex: 0 1 auto;
    }
    
    .generate-gallery-threeview-image-3 {
        display: none;
        margin: 10px;
        max-height: 70%;
        min-width: 0;
        min-height: 0;
    
        flex: 0 1 auto;
    }

    .generate-gallery-threeview-left {
        position: absolute;
        left: 50px;
        z-index: 1;
    }
    
    .generate-gallery-threeview-right {
        position: absolute;
        right: 50px;
        z-index: 1;
    }

    /* gallery view */
    .generate-gallery-galleryview-item {
        display: grid;
        width: 49%;
    }

    /* view filters */
    .generate-gallery-bar {
        width: 100%;
        height: 100%;
    
        margin: auto;
    
        display: flex;
        background: #1A1A1A;
    
        flex: 0;
    }

    .generate-gallery-bar-main {
        width: 100%;
        height: 47px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
        gap: 40px;
        padding: 10px 30px;
    }
    
    .generate-gallery-bar-main-view-filter {
        flex: 0 0 60px;
    
        background-image: url('../../assets/icons/filter-view-single-dropdown.svg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
    
        &:hover {
            cursor: pointer;
            background-color: rgba(107, 107, 107, 0.1);
            background-blend-mode: multiply;
        }
    }

    .generate-gallery-bar-main-view-filter-list-right {
        box-sizing: border-box;
    
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
    
        width: 216px;
        height: auto;
        
        position: relative;
        left: -60px;
        top: 47px;
    
        background: #383838;
        border: 0.5px solid #232526;
        border-radius: 5px;
    
        z-index: 20;
    
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .generate-gallery-bar-main-view-filter-list {
        box-sizing: border-box;
    
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
    
        width: 216px;
        height: auto;
        
        position: relative;
        left: 0px;
        top: 47px;
    
        background: #383838;
        border: 0.5px solid #232526;
        border-radius: 5px;
    
        z-index: 20;
    
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .generate-gallery-bar-main-sort-filter {
        flex: 0 0 60px;
    
        background-image: url('../../assets/icons/filter-sort-dropdown.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    
        &:hover {
            cursor: pointer;
            background-color: rgba(107, 107, 107, 0.1);
            background-blend-mode: multiply;
        }
    }
    
    .generate-gallery-bar-main-date-filter {
        flex: 0 0 100px;
    
        background-image: url('../../assets/icons/filter-date-dropdown.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    
        &:hover {
            cursor: pointer;
            background-color: rgba(107, 107, 107, 0.1);
            background-blend-mode: multiply;
        }
    }
}