

/* navbar */
.navbar {
    position: fixed;
    
    top: 0;
    height: 69px;
    width: 100%;

    padding: 0;
    margin-bottom: 0;

    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    align-items: center;
    justify-content: center;

    background-color: var(--color-header-background);
    border-bottom: 0px;

    z-index: 100;
}

/* title */
.navbar-title {
    grid-column-start: 1;
    grid-row-start: 1;

    align-items: center;
    justify-self: start;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    margin-left: 35px;
    color: var(--color-text);
}

.navbar-title-dot {
    background: linear-gradient(107.96deg, #FF4280 70.99%, #8D1DFF 75.21%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-title > a {
    color: inherit;
    text-decoration: inherit;
}

/* links */
.navbar-links {
    grid-column-start: 2;
    grid-row-start: 1;

    align-items: center;
    justify-self: center;

    display: flex;
    flex-direction: row;

    color: var(--color-text);
    text-decoration: none;
    cursor: pointer;

    gap: 10px;
}

.navbar-link-container {
    display: grid;
}

.navbar-link {
    grid-column-start: 1;
    grid-row-start: 1;

    display: grid;

    align-items: center;
    text-align: center;

    margin: auto;
    width: 69px;
    height: 32px;

    border-radius: 3px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    
    color: var(--color-text);
}

.navbar-link-selected {
    background: var(--color-input-container-background);
}

.navbar-link-notification {
    grid-column-start: 1;
    grid-row-start: 1;

    margin: auto auto -3px auto;

    width: 7px;
    height: 7px;

    border-radius: 50%;
    background: var(--color-primary);
}

/* profile */
.navbar-user {
    grid-column-start: 3;
    grid-row-start: 1;

    align-items: center;
    justify-self: end;

    display: flex;
    margin-right: 0px;
}

.navbar-user-credits {
    display: flex;
    margin-right: 33px;
}

.navbar-user-credits-icon {
    overflow: hidden;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 13px;
}

.navbar-user-credits-icon > img {
    height: 100%;
    width: 100%;
}

.navbar-user-credits-info {
    display: grid;
    padding: auto 0px auto 0px;
}

.navbar-user-credits-info-top {
    margin: auto auto 2px auto;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--color-text);
}

.navbar-user-credits-info-bottom {
    margin: 0px auto auto 0px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: var(--color-text);
}

.navbar-user-photo {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 37px;

    cursor: pointer;

    overflow: hidden;
}

.navbar-user-photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* dropdown menu */
.navbar-user-dropdown {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 19px;
    gap: 24px;

    position: absolute;
    width: 245px;

    top: calc(69px + 11px);
    right: 43px;

    background: #222222;
    border: 1px solid #444444;
    border-radius: 20px;
}

.navbar-user-dropdown-heading {
    display: flex;
    flex-direction: row;

    align-items: end;

    gap: 16px;
}

.navbar-user-dropdown-info {
    display: flex;
    flex-direction: column;

    gap: 8px;
    padding-bottom: 5px;
}

.navbar-user-dropdown-photo {
    overflow: hidden;
    width: 55px;
    height: 55px;
    border-radius: 50%;

    object-fit: cover;
}

.navbar-user-dropdown-info-name {
    width: 60px;
    height: 22px;
    
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 100%;

    color: #FFFFFF;
}

.navbar-user-dropdown-info-email {
    width: 141px;
    height: 15px;
    
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    
    color: #C1C1C1;
}

.navbar-user-dropdown-items {
    display: flex;
    flex-direction: column;

    gap: 10px
}

.navbar-user-dropdown-item {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: start;

    gap: 16px;

    padding: 5px;
    border-radius: 5px;

    &:hover {
        background-color: #444444;
        cursor: pointer;
    }
}

.navbar-user-dropdown-item-icon {
    width: 21px;
    height: 21px;
}

.navbar-user-dropdown-item-text {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.text-white {
    color: #FFFFFF;
}

.text-red {
    color: #FF0000;
}

.border-red {
    border: 3px solid #FF0000;
}

.border-enable-hover {
    &:hover {
        filter: brightness(50%);
    }
}

/* mobile */
@media (max-width:1000px)  { 
    .navbar-title {
        grid-column-start: 3;
        grid-row-start: 1;

        align-items: center;
        justify-self: start;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 140%;
        margin-right: 20px;
        color: var(--color-text);
    }

    .navbar-user {
        grid-column-start: 1;
        grid-row-start: 1;

        align-items: center;
        justify-self: start;

        display: flex;
        margin-left: 10px;
    }

    /* dropdown menu */
    .navbar-user-dropdown {
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 19px;
        gap: 24px;

        position: absolute;
        width: 245px;

        top: calc(69px + 11px);
        left: 5px;

        background: #222222;
        border: 1px solid #444444;
        border-radius: 20px;
    }
}