
/* upload blank */
.uploadnormal {
    position: absolute;
    width: 100%;
    height: 100%;
  
    background: var(--color-background);

    display: flex;
    flex-direction: column;

    padding: 40px 40px 0px 40px;
    gap: 40px;

    overflow: hidden;
}

.uploadnormal-container {
    width: 100%;
    height: auto;

    background: transparent;

    margin: 0px auto 0px auto;

    display: flex;
    flex-direction: row;

    align-items: start;
    justify-content: space-between;
}

/* buttons */
.uploadnormal-add-photos {
    width: 233px;
}

.uploadnormal-left {
    display: flex;
    flex-direction: column;

    justify-content: end;
    align-items: start;
    margin-left: 0px;

    gap: 12px;
}

.uploadnormal-right {
    display: flex;
    flex-direction: column;

    justify-content: end;
    align-items: end;
    margin-right: 0px;

    gap: 12px;
}

.uploadnormal-process-photos {
    width: 198px;

    justify-content: end;
}

.uploadnormal-callout-left {
    display: flex;
    flex-direction: row;

    width: 305px;
    height: 13px;

    align-self: center;
    justify-self: end;

    justify-content: start;

    text-align: start;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 140%;


    color: #E3E3E3;
}

.uploadnormal-callout-right {
    display: flex;
    flex-direction: row;

    width: 305px;
    height: 13px;

    align-self: center;
    justify-self: end;

    justify-content: end;

    text-align: end;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 140%;


    color: #E3E3E3;
}

.uploadnormal-exclamation-image {
    width: 10%;
    height: 100%;

    overflow: visible;

    background-image: url('../../assets/icons/circle-i.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


/* processing overlay */
.uploadnormal-overlay {
    position: absolute;
    height: 100%;
    width: 100%;

    background: rgba(35, 35, 35, 0.7);

    z-index: 20;
}

.uploadnormal-overlay-modal {
    position: relative;
    box-sizing: border-box;

    width: 722px;
    height: 285px;

    margin: auto;
    margin-top: 15%;

    background: #222222;
    border: 1px solid #444444;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 10px 50px 10px;
}

.uploadnormal-overlay-modal-header {
    width: 443px;
    height: 44px;
    
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    
    color: #FFFFFF;
}

.uploadnormal-overlay-modal-text {
    width: 419px;
    height: 44px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    text-align: center;

    color: #FFFFFF;
}

.uploadnormal-overlay-modal-progress-bar {
    width: 470px;
    height: 13px;

    overflow: hidden;

    background: linear-gradient(0deg, #B6B6B6, #B6B6B6), linear-gradient(0deg, #B6B6B6, #B6B6B6), linear-gradient(0deg, #B6B6B6, #B6B6B6), #B6B6B6;
    border-radius: 360px;
}

@keyframes uploadnormal-overlay-modal-progress-bar-progress-animation {
    from {
        margin-left: 100%;
    }
    to {
        margin-left: -100%;
    }
  }

.uploadnormal-overlay-modal-progress-bar-progress-sliver {
    width: 90%;
    height: 100%;
    
    background: linear-gradient(
        -45deg,
        #FF428000 50%,
        #f6f0f2 65%,
        #f6f0f2 65%,
        #FF428000 80%,
        #FF428000 100%
      );

    animation-name: uploadnormal-overlay-modal-progress-bar-progress-animation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.uploadnormal-overlay-modal-progress-bar-progress {
    width: 0%;
    height: 100%;

    overflow: hidden;
    
    background: linear-gradient(0deg, #FF4280, #FF4280);
}

.uploadnormal-overlay-modal-progress-text {
    width: 52px;
    height: 34px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 34px;

    color: #FFFFFF; 
}

/* mobile */
@media (max-width:1000px)  { 
    .uploadnormal {
        position: absolute;
        width: 100%;
        height: 100%;
      
        background: var(--color-background);
    
        display: flex;
        flex-direction: column;
    
        padding: 0px 10px 0px 10px;
        gap: 30px;
    
        overflow: hidden;
    }

    .uploadnormal-container {
        width: 100%;
        height: auto;
    
        background: transparent;
    
        margin: 70px auto 10px auto;
    
        display: flex;
        flex-direction: column;
    
        align-items: center;
        justify-content: space-between;
    }

    .uploadnormal-left {
        display: flex;
        flex-direction: column;
    
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    
        gap: 12px;
    }
    
    .uploadnormal-right {
        display: flex;
        flex-direction: column;
    
        justify-content: center;
        align-items: center;
        margin-right: 0px;
    
        gap: 12px;
    }

    .uploadnormal-callout-left {
        display: flex;
        flex-direction: row;
    
        width: 305px;
        height: 13px;

        margin-bottom: 10px;
    
        align-self: center;
        justify-self: center;
        justify-content: center;
        text-align: center;
    }
    
    .uploadnormal-callout-right {
        display: flex;
        flex-direction: row;
    
        width: 305px;
        height: 13px;
    
        align-self: center;
        justify-self: center;
        justify-content: center;
        text-align: center;
    }
}