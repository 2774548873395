 
 /* membership */
.membership {
  position: absolute;

  width: 100%;
  height: auto;
  min-height: 100%;

  top: 0px;

  padding-top: 25px;
  padding-bottom: 50px;

  gap: 25px;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;

  color: var(--color-text);
  background-color: var(--color-background);

  font-family: 'Avenir Next';
  font-style: normal;

  z-index: -2;
}

/* heading */
.membership-heading {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
}

.membership-heading-stylized {
  background: linear-gradient(270deg, #8D1DFF 1.92%, #FF4280 54.74%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.membership-subheading {
  width: 40%;

  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  
  text-align: center;
}

.membership-duration {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  margin-top: 30px;
  margin-bottom: 30px;

  gap: 25px;

  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
}

.membership-duration-slider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  

  background: rgba(255, 255, 255, 0.3);
  
  border-radius: 360px;

  font-weight: 600;
  font-size: 25px;
  line-height: 100%;

  cursor: pointer;
}

.membership-duration-slider-left {
  padding: 15px 20px;

  grid-column-start: 1;
  grid-row-start: 1;

  text-align: center;
  pointer-events: none;

  z-index: 1;
}

.membership-duration-slider-right {
  padding: 15px 20px;

  grid-column-start: 2;
  grid-row-start: 1;

  text-align: center;
  pointer-events: none;

  z-index: 1
}

@keyframes slideright {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slideleft {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: -100%;
  }
}

.membership-duration-slider-stylized {
  padding: 15px 20px;

  grid-column-start: 2;
  grid-row-start: 1;

  margin-left: -100%;

  height: 100%;
  width: 100%;

  background: linear-gradient(89.55deg, rgba(255, 66, 128, 0.8) 2.67%, rgba(141, 29, 255, 0.8) 96.3%);
  backdrop-filter: blur(2px);

  border-radius: 360px;

  z-index: 0;

  cursor: pointer;
}

.membership-duration-slider-stylized-left {
  animation: slideleft 0.5s ease-in; 
  -webkit-animation: slideleft 0.5s forwards;
}

.membership-duration-slider-stylized-right {
  animation: slideright 0.5s ease-in; 
  -webkit-animation: slideright 0.5s forwards;
}

/* Pricing */
.membership-price-container {
  display: flex;
  flex-direction: row;

  padding: 0px 100px;

  gap: 53px;
  
  font-family: 'Avenir Next';
  font-style: normal;

  color: #FFFFFF;
}

.membership-price {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  flex: 1 1 0%;

  justify-content: start;
  align-items: center;
  text-align: center;

  padding: 32px 24px;
  gap: 20px;

  max-width: 362px;

  background: #222222;
  border: 1px solid #444444;
  border-radius: 5px;
}

.membership-price-tier {
  background: linear-gradient(270deg, #8D1DFF 1.92%, #FF4280 54.74%),
              linear-gradient(0deg, #FFFFFF, #FFFFFF);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 600;
  font-size: 25px;
  line-height: 140%;
}

.membership-price-cost {
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
}

.membership-price-features {
  left: 0;
  margin: auto auto auto 10%;
  align-self: start;

  padding: 0;
  text-align: start;

  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}

.membership-price-spacer {
  flex: 1 1 0%;
}

.membership-price-button {
  display: flex;
  flex-direction: row;

  align-items: flex-start;
  justify-content: center;

  padding-bottom: 0;

  padding: 10px;
  gap: 10px;

  width: auto;
  height: 36px;

  background: #FF4280;
  border-radius: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  cursor: pointer;
}

/* mobile */
@media (max-width:1000px)  { 
  .membership {
    padding: 0px 20px 20px 20px;
  }

  .membership-main-heading {
    text-align: center;
  }

  .membership-heading {
    text-align: center;
  }

  .membership-subheading {
    width: 100%;
  }

  .membership-price-container {
    flex-direction: column;
    padding: 0px;
    gap: 30px;
  }

  .membership-price-button {
    font-size: 2.5vw !important;
  }

  .membership-duration-slider {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}