
@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
}

:root {
  /* font definition */
  --main-font: 'Avenir Next', 'Roboto', sans-serif;
  --secondary-font: 'Montserrat';

  --color-background: #222222;
  --color-landing-background: #0c0c0c;
  --color-left-side-bar-background: #1a1a1a;
  --color-header-background: #131517;
  --color-input-container-background: #3a3a3a;
  --color-inactive-objects: #c1c1c1;
  --color-stroke: #232526;
  --color-popup-windows-background: #222222;

  --color-text: #ffffff;

  --color-primary: #ff4280;
  --color-primary-hover: #ffa4c2;
  --color-secondary: #8d1dff;
  --color-error: #ff4242;
  --color-success: #5cff42;
}

/* default font styling for all elements */
* {
  font-family: var(--main-font);
  font-style: normal;

  color: var(--color-text);
}

/* disable text selection */
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none; /* Internet Explorer/Edge */
}

/* set default link color */
a {
  text-decoration: none;
  color: var(--color-primary);

  &:hover {
    text-decoration: none;
    color: var(--color-primary-hover);
  }
}

/* remove button styling */
.button-no-style {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.maybe-alert-bar {
  position: fixed;
  top: 4rem;
  width: 100%;
  height: 3rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.maybe-alert-bar-button {
  width: 10rem !important;
}

/* hide/show elements on mobile/desktop */
@media (max-width:1000px)  { 
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width:1000px)  { 
  .hide-on-desktop {
    display: none !important;
  }
}

/* mobile specific for main app */
@media (max-width:1000px)  { 
  .maybe-alert-bar {
    font-size: min(3vw, 10px);
    padding: 0.5rem;
  }

  .maybe-alert-bar-button {
    width: auto !important;
    padding: 10px !important;
  }
}