

/* upload blank */
.uploadblank {
    position: absolute;
    width: 100%;
    height: 100%;
  
    background: black;

    z-index: -5;
}

/* background */
.uploadblank-bg {
    position: absolute;
    width: 100%;
    height: auto;

    top: 0px;
    bottom: 0px;

    overflow: hidden;
}

.uploadblank-bg-ellipse {
    position: relative;
    width: 34%;
    height: 40%;
    margin-left: auto;
    margin-right: auto;
    top: 15%;

    background: rgba(141, 29, 255, 0.5);
    filter: blur(125px);

    z-index: -1;
}

.uploadblank-bg-rectangle {
    position: relative;
    width: 100%;
    height: 120%;
    left: 0px;
    bottom: 0px;

    background: linear-gradient(180deg, rgba(151, 71, 255, 0) 21.26%, rgba(175, 43, 141, 0.5) 52.05%, rgba(151, 71, 255, 0.5) 108.19%);
    filter: blur(125px);

    z-index: -2;
}

/* content */
.uploadblank-content {
    width: 100%;
    height: auto;

    max-width: 1500px;

    margin: 10px auto auto auto;

    display: grid;
    grid-template-columns: 0.9fr 1.5fr 0.9fr;
    grid-template-rows: 69px 50% 50%;

    z-index: 5;
}

.uploadblank-content-left {
    position: relative;
    width: 100%;
    height: 100%;

    grid-row-start: 2;
    grid-column-start: 1;

    display: flex;
    flex-direction: column;
}

.uploadblank-content-center {
    position: relative;
    width: 100%;
    height: 100%;

    grid-row-start: 2;
    grid-column-start: 2;
}

.uploadblank-content-right {
    position: relative;
    width: 100%;
    height: 100%;

    grid-row-start: 2;
    grid-column-start: 3;

    display: flex;
    flex-direction: column;
}

.uploadblank-content-bottom {
    position: relative;
    width: 100%;
    height: 100%;

    grid-row-start: 3;
    grid-column-start: 2;
}

/* sub-content */
.uploadblank-content-card {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;

    width: auto;
    height: auto;
    margin: auto;

    background: #222222;
    border: 1px solid #444444;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.uploadblank-content-card-number {
    width: 54px;
    height: 100%;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 90px;
    line-height: 100%;

    text-align: center;

    color: #FF4280;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    flex: none;
    order: 0;
    flex-grow: 0;
}

.uploadblank-content-card-text-container {
    width: 207px;
    height: 100%;

    margin: auto;
    
    display: flex;
    flex-direction: column;
}

.uploadblank-content-card-title {
    width: 100%;
    height: 30px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;

    text-align: left;

    color: #FFFFFF;

    flex: none;
    order: 0;
    flex-grow: 1;
}

.uploadblank-content-card-text {
    width: 207px;
    height: 44px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    color: #CCCCCC;

    flex: none;
    order: 1;
    flex-grow: 0;
}

/* iphone stuff */
.uploadblank-content-center-grid {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr 2fr 2fr 1fr;
}

.uploadblank-content-center-iphone {
    width: 100%;
    height: 100%;

    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 5;

    filter: drop-shadow(0px 13px 20px rgba(0, 0, 0, 0.25));

    background-image: url('../../assets/test/iphone_13.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-base {
    width: 100%;
    height: 100%;

    background-origin: content-box;
    padding: 10px;
}

.uploadblank-content-center-preview-1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-2-1 {
    grid-row-start: 2;
    grid-column-start: 3;
    grid-column-end: 5;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-2-2 {
    grid-row-start: 3;
    grid-column-start: 3;
    grid-column-end: 5;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-3-1 {
    grid-row-start: 2;
    grid-column-start: 7;
    grid-column-end: 9;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-3-2 {
    grid-row-start: 3;
    grid-column-start: 7;
    grid-column-end: 9;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-center-preview-4 {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 4;

    background-image: url('../../assets/test/example_1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

/* Bottom area */
.uploadblank-content-bottom-heading {
    margin: 20px auto 20px auto;

    width: 193px;
    height: 28px;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;

    color: #FFFFFF;
}

.uploadblank-content-bottom-upload-container {
    box-sizing: border-box;

    margin: auto auto auto auto;

    width: 351px;
    height: 223px;
    
    background: #222222;
    border: 2px dashed #FFFFFF;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
}

.uploadblank-content-bottom-upload-container > div, a {
    margin: auto auto auto auto;
}

.uploadblank-content-bottom-upload-image {
    margin-top: 20px !important;

    width: 20%;
    height: 20%;

    background-image: url('../../assets/icons/upload-1.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.uploadblank-content-bottom-upload-text {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    
    color: #FFFFFF;
}

.uploadblank-content-bottom-upload-text-small {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    
    color: #FFFFFF;
}

.uploadblank-content-bottom-upload-button {
    box-sizing: border-box;

    text-decoration:none !important; 
    cursor:pointer;  

    text-align: center;

    margin-bottom: 20px !important;

    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    
    width: 33%;
    height: 35px;
    
    border: 0.8px solid #FF4280;
    border-radius: 5px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    
    color: #FF4280 !important;
}

/* mobile */
@media (max-width:1000px)  { 
    .uploadblank {
        display: flex;
        flex-direction: column;
    }

    .uploadblank-content {
        flex: 1 1 100%;

        width: 100%;
        height: auto;
    
        min-width: 0;
        max-width: 100%;
        max-height: 100%;
        margin: 10px auto 30px auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    
        z-index: 5;
    }

    .uploadblank-bg {
        position: absolute;
        width: 100%;
        height: 100%;
    
        top: 0px;
        bottom: 0px;
    
        overflow: hidden;
    }
    
    .uploadblank-content-left {
        position: relative;
        width: 100%;
        height: 100%;
        min-width: 0;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .uploadblank-content-right {
        position: relative;
        width: 100%;
        height: 100%;
        min-width: 0;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .uploadblank-content-bottom {
        position: relative;
        width: 80%;
        height: auto;
        min-width: 0;
        margin: auto auto auto auto;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: 0;
    }

    .uploadblank-content-bottom-upload-button {
        width: 100%;
        height: auto;

        align-self: center;
        justify-self: center;

        margin: 0px !important;
        padding: 0px !important;
        border: 0;
    }

    .uploadblank-content-card {
        padding: 12px 16px;
    
        width: auto;
        height: auto;
        margin: auto 15% auto 15%;
    }

    .uploadblank-content-card-number {
        font-size: 10vh;
    }

    .uploadblank-content-card-text-container {
        width: 100%;
        max-width: 100vw;
    }

    .uploadblank-content-card-title {
        font-size: 2.5vh;
        height: auto;
        flex-grow: 0;
    }
    
    .uploadblank-content-card-text {
        width: auto;
        font-size: 1.8vh;
        height: auto;
    }
}