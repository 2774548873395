 
 /* account */
.account {
  position: absolute;

  width: 100%;
  height: auto;
  min-height: 100%;

  top: 0px;
  bottom: 0px;

  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-background);

  z-index: -2;
}

/* background */
.account-ellipse-1 {
  position: absolute;
  width: 60%;
  height: auto;
  left: 0%;
  top: 0%;

  z-index: -1;

  /* background: radial-gradient(50% 50% at center, rgba(135, 32, 148, 0.4), rgba(0, 0, 0, 0)); */
}

.account-ellipse-2 {
  position: absolute;
  width: 60%;
  height: auto;
  right: 0%;
  top: 0%;

  z-index: -1;
}

.account-spacer {
  height: 69px;
}

/* main area */
.account-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 24px;

  width: 450px;
}

/* user photo */
.account-user-photo {
  overflow: hidden;
  width: 135px;
  height: 135px;

  margin-bottom: 10px;

  border-radius: 50%;

  object-fit: cover;
}

/* buttons */
.account-nav-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 12px 16px;

  position: relative;
  width: 100%;
  height: 48px;

  gap: 20px;

  margin: auto;

  background: #3A3A3A;
  border: 1px solid #444444;
  border-radius: 10px;

  color: var(--color-text);
  cursor: pointer;

  &:hover {
    background: #444444;
  }
}

.account-nav-button-spacer {
  flex: 1 1 auto;
}

/* credit box */
.account-credit-box {
  box-sizing: border-box;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px 8px 21px;
  gap: 18px;
  
  position: relative;
  width: 100%;
  height: 111px;
  
  background: #232526;
  border: 1px solid #444444;
  border-radius: 10px;
}

.account-credit-box-spacer {
  flex: 1 1 auto;
}

.account-credit-box-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 10px;

  color: #FFFFFF;

  text-transform: uppercase;
}

.account-credit-box-heading {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  text-transform: uppercase;
}

.account-credit-box-content {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}

.account-credit-box-button {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 8px;
  gap: 10px;

  position: relative;
  width: 82px;
  height: 32px;

  border: 0.8px solid #FF4280;
  border-radius: 5px;

  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;

  color: #FF4280;

  cursor: pointer;

  &:hover {
    background: #FF4280;
    color: var(--color-text)
  }
}

/* favorites box */
.account-favorites-box {
  box-sizing: border-box;

  width: 450px;
  height: auto;

  padding: 14px 17px;
  gap: 15px;
  
  background: #3A3A3A;
  border: 1px solid #444444;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
}

.account-favorites-box-text {
  display: flex;
  flex-direction: row;

  justify-content: end;
  align-items: end;
}

.account-favorites-box-text-left {
  margin: auto auto auto 0px;
  
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  
  color: #FFFFFF;
}

.account-favorites-box-text-right {
  margin: auto 0px auto auto;
  padding-top: 2px;
  
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  
  color: #C1C1C1;

  cursor: pointer;

  &:hover {
    color: #FFFFFF;
  }
}

.account-favorites-box-images {
  display: flex;
  flex-direction: row;

  gap: 6px;
}

.account-favorites-box-image {
  width: 78px;
  height: 78px;

  min-width: 0;
  min-height: 0;
  
  background: #828282;
  border-radius: 5px;

  flex: 0 0 auto;

  object-fit: cover;
}

/* promo box */
.account-promo-box {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 16px;

  position: relative;
  width: 100%;
  height: 48px;

  gap: 20px;

  margin: auto;

  background: #3A3A3A;
  border: 1px solid #444444;
  border-radius: 10px;

  color: var(--color-text);
  cursor: pointer;

  &:hover {
    background: #444444;
  }
}

.account-promo-box-text-left {
  flex: 1 1 50%;
}

.account-promo-box-text-input {
  height: 100%;
  width: 100%;
  min-width: 100px;

  padding: 0px 10px;
  margin: 0px;

  background: #111111;
  border: 1px solid #444444;
  border-radius: 10px;

  color: var(--color-text);

  &:hover {
    background: #222222;
  }
}

.account-promo-submit {
  width: auto;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

/* mobile */
@media (max-width:1000px)  { 
  .account-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    gap: 24px;
  
    width: 100%;
    overflow: hidden;
    padding: 0px 20px;
  }
}